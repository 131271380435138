import React, { useEffect, useState } from 'react';
import {
    DialogContent,
    DialogTitle,
    Box,
    Typography,
    Stack,
    Button,
    Dialog,
    DialogActions,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormHelperText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Exportable from './../../../service/Exportable';
import Feedback from '../../../service/Feedback';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { getCycleSelected } from '../../../store/slices/background';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import Slide from '@mui/material/Slide';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Services from '../../../service/Connection';
import { DatePicker, LocalizationProvider, esES } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import useFeedback from '../../../hooks/useFeedback';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import GridOnIcon from '@mui/icons-material/GridOn';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

/**
 * Modal  para exportar
 *
 * @param {*} props
 *
 * @returns
 */
const ExportablesModal = ({
    show,
    data,
    selectedTitle,
    reportType,
    onClose,
}) => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const feedbackApp = useFeedback();

    ////////////////// SHARED STATE ////////////////

    const schoolCycle = useSelector(getCycleSelected);
    const config = useSelector(selectSettingBySchoolId(schoolId));

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    ////////////////// LOCAL STATE  //////////////////

    const [feedback] = useState(() => new Feedback());
    const [downloading, setDownloading] = useState('');
    const [directors, setDirectors] = useState([]);
    const [schoolinfo, setSchoolInfo] = useState([]);
    const [parents, setParents] = useState([]);
    const [parentsRequest, setParentsRequest] = useState('');
    const [directorsRequest, setDirectorsRequest] = useState('');
    const [photoRequest, setPhotoRequest] = useState('');
    const [conductType, setConductType] = useState('');
    const [weightRequest, setWeightRequest] = useState('');
    const [fileRequest, setFileRequest] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [checked, setChecked] = useState({
        weight: false,
        height: false,
        size: false,
    });

    /**
     * Estado para los datos de reporte de carta compromiso
     */
    const [parentsCommitmentLetter, setParentsCommitmentLetter] = useState({
        minimum_average: '',
        attendance_percentage: '',
        homework_percentage: '',
    });

    const [withoutchecked, setWithoutChecked] = useState({
        weight: true,
        height: true,
        size: true,
    });

    const OptionalPhoto = ['Sin Fotografia', 'Con Fotografia'];
    const conductTypes = [
        {
            value: 1,
            text: 'Buena',
        },
        {
            value: 2,
            text: 'Regular',
        },
        {
            value: 3,
            text: 'Mala',
        },
    ];
    const Optionalweight = [
        'Con Medidas Antropométricas',
        'Sin Medidas Antropométricas',
    ];
    const OptionalFile = ['PDF', 'EXCEL', 'ZIP'];

    ////////////////// ACTIONS /////////////////////
    const handleDirectorsRequestChange = (event) => {
        const selectedValue = event.target.value;
        setDirectorsRequest(selectedValue);
    };

    const handleParentsRequestChange = (event) => {
        const selectedValue = event.target.value;
        setParentsRequest(selectedValue);
    };

    const handleStartDateChange = (date) => {
        const formattedDate = date ? date.toISODate() : null;
        setStartDate(formattedDate);
    };

    const handleEndDateChange = (date) => {
        const formattedDate = date ? date.toISODate() : null;
        setEndDate(formattedDate);
    };

    const handlePhotoRequestChange = (event) => {
        const selectedValue = event.target.value;
        setPhotoRequest(selectedValue);
    };

    const handleConductTypeChange = (event) => {
        const selectedValue = event.target.value;
        setConductType(selectedValue);
    };

    const handleFileRequesChange = (event) => {
        const selectedValue = event?.target?.value;

        if (selectedValue !== undefined) {
            setFileRequest(selectedValue);
        } else {
            console.error('No se pudo obtener el valor de event.target');
        }
    };

    const handleOptionChange = (option) => {
        setChecked((prevState) => ({
            ...prevState,
            [option]: !prevState[option],
        }));
    };

    /**
     * manejar de eventos para el reporte de carta compromiso
     */
    const handleParentsCommitmentLetterChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setParentsCommitmentLetter({
            ...parentsCommitmentLetter,
            [name]: value
                .replace(/[^0-9.]|(?<=\..*)\./g, '')
                .replace(/(\.\d{2})\d+/g, '$1'),
        });
    };

    const handleWeightRequestChange = (event) => {
        const selectedValue = event?.target?.value;
        setWeightRequest(selectedValue || ''); // Asignar '' si selectedValue es undefined

        if (selectedValue === 'Con Medidas Antropométricas') {
            setChecked({
                weight: checked.weight,
                height: checked.height,
                size: checked.size,
            });
        } else if (selectedValue === 'Sin Medidas Antropométricas') {
            setChecked({
                weight: withoutchecked.weight,
                height: withoutchecked.height,
                size: withoutchecked.size,
            });
        }
    };

    /**
     * Efecto general ejecutado cuendo el modal abre la pantalla
     */
    useEffect(() => {
        if (!show) {
            setParentsCommitmentLetter({
                minimum_average: '',
                attendance_percentage: '',
                homework_percentage: '',
            });
        }
    }, [show]);

    let snaphot = null;

    useEffect(() => {
        const fetchDirectors = async () => {
            try {
                if (schoolId) {
                    const response =
                        await Services.getDirectorsBySchool(schoolId);
                    let activeDirectors = response.data.data.filter(
                        (director) => director.status === 1
                    );

                    setDirectors(activeDirectors);
                }
            } catch (error) {
                console.error('Error al obtener directores:', error);
            }
        };

        fetchDirectors();
    }, [schoolId]);

    useEffect(() => {
        const fetchParents = async () => {
            try {
                if (data.query.student_id) {
                    const response = await Services.getParentsByStudent(
                        data.query.student_id
                    );
                    setParents(response.data.data);
                }
            } catch (error) {
                console.error('Error al obtener directores:', error);
            }
        };

        fetchParents();
    }, [show]);

    useEffect(() => {
        const fetchParents = async () => {
            try {
                if (schoolId) {
                    const response = await Services.getSchoolById(schoolId);
                    setSchoolInfo(response.data.data);
                }
            } catch (error) {
                console.error('Error al obtener la escuela:', error);
            }
        };

        fetchParents();
    }, [show]);

    if (schoolCycle.cycle_id != -1) {
        snaphot = schoolCycle.snaphot;
    }

    const handleClose = () => {
        setDirectorsRequest('');
        setStartDate('');
        setEndDate('');
        setParentsRequest('');
        setFileRequest('');
        setPhotoRequest('');
        setConductType('');
        setWeightRequest();
        setChecked({
            weight: false,
            height: false,
            size: false,
        });
        handleWeightRequestChange('');
        onClose();
    };

    function onExportExcel() {
        exportNow('xlsx');
    }

    function onExportPDF() {
        exportNow('pdf');
    }

    function onExportZIP() {
        exportNow('zip');
    }

    const children = (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            <FormControlLabel
                label="Peso"
                control={
                    <Checkbox
                        checked={checked.weight}
                        onChange={() => handleOptionChange('weight')}
                        color="primary"
                    />
                }
                sx={{ color: 'black' }}
            />
            <FormControlLabel
                label="Altura"
                control={
                    <Checkbox
                        checked={checked.height}
                        onChange={() => handleOptionChange('height')}
                        color="primary"
                    />
                }
                sx={{ color: 'black' }}
            />
            <FormControlLabel
                label="Talla"
                control={
                    <Checkbox
                        checked={checked.size}
                        onChange={() => handleOptionChange('size')}
                        color="primary"
                    />
                }
                sx={{ color: 'black' }}
            />
        </Box>
    );

    const principalsReportPool = [
        { reportkey: 'proof-student-good-conduct' },
        { reportkey: 'proof-student-studies' },
        { reportkey: 'proof-student-transfer' },
        { reportkey: 'proof-student-passport' },
        { reportkey: 'proof-student-vacations' },
        { reportkey: 'request-student-enrollment' },
        { reportkey: 'proof-student-unenrollment' },
        { reportkey: 'proof-student-enrollment' },
        { reportkey: 'student-credential' },
    ];
    const professorsReports = [
        { reportkey: 'subject-assists' },
        { reportkey: 'subject-califications' },
    ];
    const studentsReports = [{ reportkey: 'subject-assists' }];
    const allowedReports = [
        'request-student-enrollment',
        'label-student-correspondence',
        'proof-student-unenrollment',
        'proof-student-enrollment',
    ];

    const showSelectAndButton = studentsReports.some(
        (report) => report.reportkey === data.report
    );

    const oneChekedValidation = Object.values(checked).some(
        (prop) => prop === true
    );

    /**
     * Funcion que comunica con el sistema de exportacion
     *
     * @param {*} format
     */
    function exportNow(format) {
        setDownloading(format);
        const FeedbackService = new Feedback();
        const director_id = directorsRequest;
        const parent_id = parentsRequest;
        const VacationsStars = startDate;
        const VacationsEnds = endDate;
        const photo =
            photoRequest === 'Con Fotografia'
                ? 1
                : photoRequest === 'Sin Fotografia'
                ? 0
                : null;
        //const imc = weightRequest === 'Con Medidas Antropométricas' ? 1 : (weightRequest === 'Sin Medidas Antropométricas' ? 0 : null);
        const turn = schoolinfo.turn;
        const selectedOptions = {
            weight: checked.weight ? 'weight' : '',
            height: checked.height ? 'height' : '',
            size: checked.size ? 'size' : '',
        };

        // Construir la cadena imc: peso | altura | talla
        const imc = Object.entries(selectedOptions)
            .filter(([key, value]) => value !== '')
            .map(([key, value]) => value)
            .join('|'); // Unir con '|'

        let imcValue;

        if (weightRequest === 'Con Medidas Antropométricas') {
            imcValue = 'with';
        } else {
            imcValue = 'without';
        }

        let reportSpecificFields = {};

        switch (data.report) {
            case 'proof-student-good-conduct':
            case 'proof-student-studies':
            case 'proof-student-transfer':
            case 'proof-student-passport':
            case 'proof-student-enrollment':
            case 'student-credential':
                reportSpecificFields = { director_id, type: conductType };
                break;

            case 'proof-student-vacations':
                reportSpecificFields = {
                    initial_vacation_day: VacationsStars,
                    final_vacation_day: VacationsEnds,
                    director_id,
                };
                break;

            case 'label-student-correspondence':
                reportSpecificFields = { parent_id };
                break;

            case 'request-student-enrollment':
            case 'proof-student-unenrollment':
                reportSpecificFields = { parent_id, director_id };
                break;

            case 'students-report-photo':
                reportSpecificFields = { photo: photo };
                break;

            case 'students-report-imc':
                reportSpecificFields = { imc: imc, condition: imcValue };
                break;

            case 'students-911-form-report':
                reportSpecificFields = { turn: turn };
                break;

            case 'cycle-start-report':
            case 'parents-report':
                reportSpecificFields = {};
                break;
            case 'parents-commitment-letter':
                reportSpecificFields = {
                    parent_id,
                    director_id,
                    ...parentsCommitmentLetter,
            };
            break;
            case 'proof-income-to-school':
                reportSpecificFields = {
                    director_id,
                };
            break;

            default:
                break;
        }

        const newData = {
            ...data,
            query: { ...data.query, ...reportSpecificFields },
        };
        const currentDateTime = DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss');

        Exportable.export(data.report, format, newData.query, snaphot)

            .then((result) => {
                const ext = format === 'pdf' ? '.pdf' : '.xlsx';

                switch (data.report) {
                    case 'proof-student-good-conduct':
                        exportToFile(
                            result.data,
                            'Constancia de buena conducta' + currentDateTime
                        );
                        break;

                    case 'proof-student-studies':
                        exportToFile(
                            result.data,
                            'Constancia de estudio' + currentDateTime
                        );
                        break;

                    case 'proof-student-transfer':
                        exportToFile(
                            result.data,
                            'Constancia de traslado' + currentDateTime
                        );
                        break;

                    case 'proof-student-passport':
                        exportToFile(
                            result.data,
                            'Constancia para pasaporte' + currentDateTime
                        );
                        break;

                    case 'proof-student-passport':
                        exportToFile(
                            result.data,
                            'Constancia para pasaporte' + currentDateTime
                        );
                        break;

                    case 'proof-student-vacations':
                        exportToFile(
                            result.data,
                            'Constancia para periodo de vacaciones' +
                                currentDateTime
                        );
                        break;

                    case 'student-credential':
                        exportToFile(
                            result.data,
                            'Credencial' + currentDateTime
                        );
                        break;

                    case 'label-student-file':
                        exportToFile(
                            result.data,
                            'Etiqueta para correspondencia de alumno' +
                                currentDateTime
                        );
                        break;

                    case 'label-student-correspondence':
                        exportToFile(
                            result.data,
                            'Etiqueta para correspondecia' + currentDateTime
                        );
                        break;

                    case 'request-student-enrollment':
                        exportToFile(
                            result.data,
                            'Formato de Inscripcion' + currentDateTime
                        );
                        break;

                    case 'proof-student-enrollment':
                        exportToFile(
                            result.data,
                            'Constancia Memorandum para alta' + currentDateTime
                        );
                        break;

                    case 'proof-student-unenrollment':
                        exportToFile(
                            result.data,
                            'Constancia Memorandum para baja' + currentDateTime
                        );
                        break;

                    case 'students-report-photo':
                        exportToFile(
                            result.data,
                            'Reporte de Alumnos con y sin fotografia' +
                                currentDateTime
                        );
                        break;

                    case 'students-report-imc':
                        exportToFile(
                            result.data,
                            'Medidas Antropométricas' + currentDateTime
                        );
                        break;

                    case 'students-911-form-report':
                        exportToFile(
                            result.data,
                            'Forma 911' + currentDateTime
                        );
                        break;

                    case 'cycle-start-report':
                        exportToFile(
                            result.data,
                            'Reporte de Inicio de Ciclo' + currentDateTime
                        );
                        break;

                    case 'parents-report':
                        exportToFile(
                            result.data,
                            'Reporte de Padres de Familia' + currentDateTime
                        );
                        break;
                    case 'parents-commitment-letter':
                        exportToFile(
                            result.data,
                            'Carta compromiso' + currentDateTime
                        );
                        break;
                    default:
                        exportToFile(result.data, 'Reporte' + currentDateTime);
                        break;
                }

                onClose();
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(error).title,
                });
            })
            .finally(() => {
                setDownloading('');
                handleClose('');
            });
    }

    /**
     * Exportar el archivo
     *
     * @param {*} blob
     * @param {*} filename
     */
    function exportToFile(blob, filename) {
        try {
            saveAs(blob, filename);
        } catch (err) {
            throw err;
        }
    }

    /**
     * Validacion de la calificacion minima
     */
    const directorValidation = () => ({
        invalid: directorsRequest === '',
        message: 'Campo requerido o incorrecto',
    });

    const reportPhotoValidation = () => ({
        invalid: photoRequest === '',
        message: 'Campo requerido o incorrecto',
    });

    const conductTypeValidation = () => ({
        invalid: conductType === '',
        message: 'Campo requerido o incorrecto',
    });

    const imcValidation = () => ({
        invalid: !oneChekedValidation,
        message: 'Campo requerido o incorrecto',
    });

    /**
     * Validación de la calificación mínima
     */
    const parentsCommitmentLetterMinimumAverage = () => {
        const value = parentsCommitmentLetter.minimum_average;
        const isInvalid =
            value === '' ||
            value > 10 ||
            value <= 0 ||
            !/^\d+(\.\d+)?$/.test(value);
        return {
            invalid: isInvalid,
            message: 'Campo requerido o incorrecto',
        };
    };

    /**
     * Validación del porcentaje de asistencia
     */
    const parentsCommitmentLetterAttendancepercentage = () => {
        const value = parentsCommitmentLetter.attendance_percentage;
        const isInvalid =
            value === '' ||
            value > 100 ||
            value <= 0 ||
            !/^\d+(\.\d+)?$/.test(value);
        return {
            invalid: isInvalid,
            message: 'Campo requerido o incorrecto',
        };
    };

    /**
     * Validación del porcentaje de tareas
     */
    const parentsCommitmentLetterHomeworkPercentage = () => {
        const value = parentsCommitmentLetter.homework_percentage;
        const isInvalid =
            value === '' ||
            value > 100 ||
            value <= 0 ||
            !/^\d+(\.\d+)?$/.test(value);
        return {
            invalid: isInvalid,
            message: 'Campo requerido o incorrecto',
        };
    };

    /**
     * Determina si el formularios esta habilitado para
     * descargar un reporte
     */
    const isInvalidForm = () => {
        if (data.report == 'parents-commitment-letter') {
            return (
                parentsCommitmentLetterMinimumAverage().invalid ||
                parentsCommitmentLetterAttendancepercentage().invalid ||
                parentsCommitmentLetterHomeworkPercentage().invalid ||
                directorValidation().invalid
            );
        }

        if (data.report === 'proof-student-good-conduct') {
            return (
                directorValidation().invalid || conductTypeValidation().invalid
            );
        }

        if (data.report === 'student-credential') {
            return directorValidation().invalid;
        }

        if (data.report === 'students-report-photo') {
            return reportPhotoValidation().invalid;
        }

        if (data.report === 'students-report-imc') {
            return imcValidation().invalid;
        }

        return false;
    };

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            disableEscapeKeyDown={downloading !== ''}
            aria-labelledby="responsive-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Slide}
            TransitionProps={{
                direction: 'left',
            }}
            PaperProps={{
                style: {
                    color: fontColor.value,
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    height: '100%',
                    width: '300px',
                    overflowY: 'auto',
                },
            }}>
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    textAlign: 'center',
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}>
                Exportador
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '16px',
                    }}>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        fontWeight="bold"
                        sx={{ mt: 2, textAlign: 'center', color: 'black' }}>
                        {selectedTitle}
                    </Typography>
                    <PostAddIcon
                        sx={{ fontSize: 72, color: titleBarBackground.value }}
                    />
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ mt: 2, textAlign: 'center', color: 'gray' }}>
                        Seleccionar formato a exportar
                    </Typography>
                    {allowedReports.includes(data.report) && (
                        <>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                sx={{ paddingTop: 2 }}>
                                <TextField
                                    labelId="requester-label"
                                    id="requester"
                                    name="¿Quién lo solicita?"
                                    value={parentsRequest}
                                    onChange={handleParentsRequestChange}
                                    fullWidth
                                    label="¿Quién lo solicita?"
                                    variant="outlined"
                                    select>
                                    <MenuItem value="" />
                                    {parents.map((parent) => (
                                        <MenuItem
                                            key={parent.parent_id}
                                            value={parent.parent_id}>
                                            {`${parent.name} ${parent.last_name}`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </>
                    )}
                    {principalsReportPool.some(
                        (report) => report.reportkey === data.report
                    ) && (
                        <>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                sx={{ paddingTop: 2 }}
                                required
                                error={directorValidation().invalid}>
                                <TextField
                                    labelId="directors-request-label"
                                    id="directors-request"
                                    name="Director en turno"
                                    value={directorsRequest}
                                    onChange={handleDirectorsRequestChange}
                                    label="Director en turno"
                                    fullWidth
                                    variant="outlined"
                                    select
                                    InputLabelProps={{
                                        shrink: directorsRequest,
                                    }}>
                                    <MenuItem value="" />
                                    {directors.map((director) => (
                                        <MenuItem
                                            key={director.user_id}
                                            value={director.user_id}>
                                            {`${director.name} ${director.last_name}`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {directorValidation().invalid && (
                                    <FormHelperText error>
                                        {directorValidation().message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </>
                    )}
                    {data.report === 'students-report-photo' && (
                        <>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                sx={{ paddingTop: 2 }}
                                required
                                error={reportPhotoValidation().invalid}>
                                <InputLabel
                                    id="photo-request-label"
                                    sx={{ paddingTop: 2 }}>
                                    Informe de Alumnos
                                </InputLabel>
                                <Select
                                    labelId="photo-request-label"
                                    id="photo-request"
                                    name="Informe de Alumnos (Con o sin fotografia)"
                                    value={photoRequest}
                                    onChange={handlePhotoRequestChange}
                                    label="Informe de Alumnos (Con o sin fotografia)">
                                    <MenuItem value="" />
                                    {OptionalPhoto.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    )}
                    {data.report === 'proof-student-good-conduct' && (
                        <>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                sx={{ paddingTop: 2 }}
                                required
                                error={conductTypeValidation().invalid}>
                                <InputLabel
                                    id="conduct-type-label"
                                    sx={{ paddingTop: 2 }}>
                                    Tipo de Conducta
                                </InputLabel>
                                <Select
                                    labelId="conduct-type-label"
                                    id="conduct-type"
                                    name="Tipo de Conducta"
                                    value={conductType}
                                    onChange={handleConductTypeChange}
                                    label="Tipo de Conducta">
                                    <MenuItem value="" />
                                    {conductTypes.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}>
                                            {option.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    )}
                    {data.report === 'students-report-imc' && (
                        <>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                sx={{ paddingTop: 2 }}
                                required
                                error={imcValidation().invalid}>
                                <InputLabel
                                    id="weightrequest-label"
                                    sx={{ paddingTop: 2 }}>
                                    (Medidas Antropométricas)
                                </InputLabel>
                                <Select
                                    labelId="weight-request-label"
                                    id="weight-request"
                                    name="(Con o sin Medidas Antropométricas)"
                                    value={weightRequest}
                                    onChange={handleWeightRequestChange}
                                    label="(Con o sin Medidas Antropométricas)">
                                    <MenuItem value="" />
                                    {Optionalweight.map((weight) => (
                                        <MenuItem key={weight} value={weight}>
                                            {weight}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {weightRequest && (
                                    <Divider
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            mx: 'auto',
                                            width: '100%',
                                        }}
                                    />
                                )}
                                {weightRequest ===
                                    'Con Medidas Antropométricas' && (
                                    <div> {children} </div>
                                )}
                                {weightRequest ===
                                    'Sin Medidas Antropométricas' && (
                                    <div> {children} </div>
                                )}
                            </FormControl>
                        </>
                    )}

                    {data.report === 'proof-student-vacations' && (
                        <LocalizationProvider
                            dateAdapter={AdapterLuxon}
                            localeText={
                                esES.components.MuiLocalizationProvider
                                    .defaultProps.localeText
                            }
                            adapterLocale="es-MX">
                            <Box paddingTop={2}>
                                <Stack spacing={3}>
                                    <DatePicker
                                        label="Inicio"
                                        value={
                                            startDate
                                                ? DateTime.fromISO(startDate)
                                                : null
                                        }
                                        onChange={handleStartDateChange}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </Stack>
                            </Box>
                            <Box paddingTop={2}>
                                <Stack spacing={3}>
                                    <DatePicker
                                        label="Fin"
                                        value={
                                            endDate
                                                ? DateTime.fromISO(endDate)
                                                : null
                                        }
                                        onChange={handleEndDateChange}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </Stack>
                            </Box>
                        </LocalizationProvider>
                    )}

                    {data.report === 'parents-commitment-letter' && (
                        <Stack spacing={2} direction={'column'}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                sx={{ paddingTop: 2 }}
                                required
                                error={directorValidation().invalid}>
                                <TextField
                                    labelId="directors-request-label"
                                    id="directors-request"
                                    name="Director en turno"
                                    value={directorsRequest}
                                    onChange={handleDirectorsRequestChange}
                                    label="Director en turno"
                                    fullWidth
                                    variant="outlined"
                                    select
                                    InputLabelProps={{
                                        shrink: directorsRequest,
                                    }}>
                                    <MenuItem value="" />
                                    {directors.map((director) => (
                                        <MenuItem
                                            key={director.user_id}
                                            value={director.user_id}>
                                            {`${director.name} ${director.last_name}`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {directorValidation().invalid && (
                                    <FormHelperText error>
                                        {directorValidation().message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    labelId="requester-label"
                                    id="requester"
                                    name="¿Quién lo solicita?"
                                    value={parentsRequest}
                                    onChange={handleParentsRequestChange}
                                    fullWidth
                                    label="¿Quién lo solicita?"
                                    variant="outlined"
                                    select>
                                    <MenuItem value="" />
                                    {parents.map((parent) => (
                                        <MenuItem
                                            key={parent.parent_id}
                                            value={parent.parent_id}>
                                            {`${parent.name} ${parent.last_name}`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>

                            <TextField
                                id="outlined-basic"
                                label="Calificación mínima"
                                variant="outlined"
                                name="minimum_average"
                                value={parentsCommitmentLetter.minimum_average}
                                onChange={handleParentsCommitmentLetterChange}
                                error={
                                    parentsCommitmentLetterMinimumAverage()
                                        .invalid
                                }
                                helperText={
                                    parentsCommitmentLetterMinimumAverage()
                                        .invalid
                                        ? parentsCommitmentLetterMinimumAverage()
                                              .message
                                        : false
                                }
                            />

                            <TextField
                                id="outlined-basic"
                                label="Porcentaje de asistencia"
                                variant="outlined"
                                name="attendance_percentage"
                                value={
                                    parentsCommitmentLetter.attendance_percentage
                                }
                                onChange={handleParentsCommitmentLetterChange}
                                error={
                                    parentsCommitmentLetterAttendancepercentage()
                                        .invalid
                                }
                                helperText={
                                    parentsCommitmentLetterAttendancepercentage()
                                        .invalid
                                        ? parentsCommitmentLetterAttendancepercentage()
                                              .message
                                        : false
                                }
                            />

                            <TextField
                                id="outlined-basic"
                                label="Porcentaje de tareas"
                                variant="outlined"
                                name="homework_percentage"
                                value={
                                    parentsCommitmentLetter.homework_percentage
                                }
                                onChange={handleParentsCommitmentLetterChange}
                                error={
                                    parentsCommitmentLetterHomeworkPercentage()
                                        .invalid
                                }
                                helperText={
                                    parentsCommitmentLetterHomeworkPercentage()
                                        .invalid
                                        ? parentsCommitmentLetterHomeworkPercentage()
                                              .message
                                        : false
                                }
                            />
                        </Stack>
                    )}

                    {
                        data.report == 'proof-income-to-school' && (
                            <Stack spacing={2} direction={'column'}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    sx={{ paddingTop: 2 }}
                                    required
                                    error={directorValidation().invalid}>
                                    <TextField
                                        labelId="directors-request-label"
                                        id="directors-request"
                                        name="Director en turno"
                                        value={directorsRequest}
                                        onChange={handleDirectorsRequestChange}
                                        label="Director en turno"
                                        fullWidth
                                        variant="outlined"
                                        select
                                        InputLabelProps={{
                                            shrink: directorsRequest,
                                        }}>
                                        <MenuItem value="" />
                                        {directors.map((director) => (
                                            <MenuItem
                                                key={director.user_id}
                                                value={director.user_id}>
                                                {`${director.name} ${director.last_name}`}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {directorValidation().invalid && (
                                        <FormHelperText error>
                                            {directorValidation().message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        )
                    }

                    <Divider sx={{ mt: 2, mx: 'auto', width: '100%' }} />
                    {showSelectAndButton && (
                        <FormControl
                            variant="outlined"
                            fullWidth
                            sx={{ paddingTop: 2 }}>
                            <InputLabel
                                id="file-request-label"
                                sx={{ paddingTop: 2 }}>
                                Tipo de Archivo
                            </InputLabel>
                            <Select
                                labelId="file-request-label"
                                id="file-request"
                                name="Tipo de Archivo"
                                value={fileRequest}
                                onChange={handleFileRequesChange}
                                label="Tipo de Archivo">
                                <MenuItem value="" />
                                {OptionalFile.map(
                                    (file) =>
                                        !(
                                            [
                                                'Asistencias',
                                                'Calificaciones',
                                            ].includes(selectedTitle) &&
                                            file === 'ZIP'
                                        ) && (
                                            <MenuItem key={file} value={file}>
                                                {file}
                                            </MenuItem>
                                        )
                                )}
                            </Select>
                            <Divider sx={{ mt: 2, mx: 'auto', width: '90%' }} />
                        </FormControl>
                    )}
                </div>
            </DialogContent>
            <Divider sx={{ mt: 2, mx: 'auto', width: '90%' }} />
            <DialogActions>
                {showSelectAndButton ? (
                    <Stack>
                        <LoadingButton
                            variant="contained"
                            color={fileRequest === 'PDF' ? 'error' : 'success'}
                            loading={false}
                            loadingPosition="start"
                            onClick={() => {
                                switch (fileRequest) {
                                    case 'EXCEL':
                                        onExportExcel();
                                        break;
                                    case 'PDF':
                                        onExportPDF();
                                        break;
                                    case 'ZIP':
                                        onExportZIP();
                                        break;
                                    default:
                                }
                            }}
                            startIcon={
                                fileRequest === 'PDF' ? (
                                    <PictureAsPdfIcon />
                                ) : fileRequest === 'ZIP' ? (
                                    <FolderZipIcon />
                                ) : (
                                    <GridOnIcon />
                                )
                            }
                            disabled={!fileRequest || downloading !== ''}>
                            Descargar
                        </LoadingButton>
                    </Stack>
                ) : (
                    <LoadingButton
                        variant="contained"
                        color={
                            data.report === 'students-in-school-info'
                                ? 'success'
                                : 'error'
                        }
                        loading={
                            downloading ===
                            (data.report === 'students-in-school-info'
                                ? 'xlsx'
                                : 'pdf')
                        }
                        loadingPosition="start"
                        onClick={() => {
                            if (data.report === 'students-in-school-info') {
                                onExportExcel();
                            } else {
                                onExportPDF();
                            }
                        }}
                        startIcon={
                            data.report === 'students-in-school-info' ? (
                                <GridOnIcon />
                            ) : (
                                <PictureAsPdfIcon />
                            )
                        }
                        disabled={downloading !== '' || isInvalidForm()}>
                        Descargar
                    </LoadingButton>
                )}

                <Button
                    onClick={handleClose}
                    autoFocus
                    variant="contained"
                    disabled={downloading !== ''}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExportablesModal;
