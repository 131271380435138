import React, { useState } from 'react';
import {
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    Avatar,
    Alert,
    Stack,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Box,
    CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import Services from '../../../service/Connection';
import { selectActivateTracedStudentStatus } from '../../../store/slices/studentsUI/operationsSlice';
import useFeedback from '../../../hooks/useFeedback';
import { useEffect } from 'react';
import { activateUser } from '../../../store/slices/usersUI';
import { Tag } from 'antd';
import UsersSearchCura from '../../../components/Search/UsersSearchCura';

/**
 * Modal para actualizar o agregar usuario
 *
 * @param {*} param0
 * 
 * @returns
 */
const AddCuraUserModal = ({ openModal, setOpenModal }) => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const feedbackApp = useFeedback();
    const dispatch = useDispatch();

    ////////////////// SHARED STATE /////////////////

    const statusOperation = useSelector(selectActivateTracedStudentStatus);
    const config = useSelector(selectSettingBySchoolId(schoolId));

    ///////////////// LOCAL STATE //////////////////

    const [error, setError] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState('idle');
    const [userSelected, setUserSelected] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [schoolName, setSchoolName] = useState('');

    //////////////////// CONFIGURACIONES //////////////////////////

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    /////////////////// ACTIONS ////////////////////

    /**
     * Registrar nuevo usuario en la escuela
     * @param {*} info
     * @param {*} status
     */
    const ActiveCuraUser = async () => {
        let statusFinal = 1;
        let mySchoolId = schoolId;
        let data = {
            user: userSelected,
            userId: userSelected.user_id,
            status: statusFinal,
            school_id: mySchoolId,
        };
        setLoadingStatus('pending');
        dispatch(
            activateUser({
                user: userSelected,
                userId: userSelected.user_id,
                data,
            })
        )
            .unwrap()
            .then((response) => {
                feedbackApp.showFeedback({
                    title: 'Usuario dado de alta',
                });
                setLoadingStatus('idle');
                setOpenModal(false);
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
                setLoadingStatus('idle');
            });
    };

    useEffect(() => {
        if (!openModal) {
            resetForm();
        }
    }, [openModal]);

    const resetForm = () => {
        setOpenModal(false);
        setUserSelected(null);
        setError(false);
    };

    const handleCloseError = () => {
        setErrorMessage('');
    };

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenModal(false);
    };

    const roles = {
        Administrador: '10000000000000000000',
        Profesor: '01000000000000000000',
        Asesor: '00100000000000000000',
        Padre: '00010000000000000000',
        Vocero: '00001000000000000000',
        Director: '00000100000000000000',
        Administrativo: '00000010000000000000',
        Tutor: '00000001000000000000',
        AdminTech: '00000000100000000000',
        SuperAdmin: '00000000010000000000',
    };

    const getUserRole = () => {
        const userRole = [];

        for (const [rol, valor] of Object.entries(roles)) {
            if (
                userSelected?.group_id.charAt(roles[rol].indexOf('1')) === '1'
            ) {
                let color;
                switch (rol) {
                    case 'Padre':
                    case 'Tutor':
                        color = 'green';
                        break;
                    case 'Profesor':
                        color = 'yellow';
                        break;
                    case 'Orientador':
                        color = 'orange';
                        break;
                    case 'Supervisor':
                        color = 'blue';
                        break;
                    case 'Director':
                        color = 'black';
                        break;
                    case 'Administrador':
                        color = 'cyan';
                        break;
                    default:
                        color = 'default';
                }
                userRole.push(
                    <Tag key={rol} color={color} sx={{ mt: 1 }}>
                        {rol}
                    </Tag>
                );
            }
        }
        return userRole;
    };

    const searchUserCura = async () => {
        if (!userSelected) return;
        setLoadingStatus('pending');
        try {
            const school = await Services.getSchoolById(
                userSelected.school_id,
                {
                    'not-calculate-properties': true,
                }
            ).then((res) => res.data.data);
            setSchoolName(school);
            setLoadingStatus('fullfiled');
        } catch (error) {
            console.error('Error:', error);
            setLoadingStatus('idle');
        }
    };

    useEffect(() => {
        searchUserCura();
    }, [userSelected]);

    const onSelectUser = (selectedUser) => {
        setUserSelected(selectedUser);
    };

    const getUserAlertContent = () => {
        if (loadingStatus === 'fullfiled') {
            if (
                userSelected?.status === 0 &&
                userSelected?.school_id === schoolId
            ) {
                return (
                    <Alert severity="info">
                        El usuario con el ID{' '}
                        <strong>{userSelected?.student_id}</strong> (
                        <strong>{userSelected?.name}</strong>{' '}
                        <strong>{userSelected?.last_name}</strong>{' '}
                        <strong>{userSelected?.second_last_name}</strong>) ya se
                        encuentra inactivo en esta misma escuela:{' '}
                        <strong>{schoolName.name}</strong>, CCT:{' '}
                        <strong>{schoolName.clave}</strong> en{' '}
                        <strong>{schoolName.location}</strong> Tel.{' '}
                        <strong>{schoolName.cellphone}</strong>. Para activarlo
                        por favor utiliza el switch en la columna activo en la
                        tabla de usuarios.
                    </Alert>
                );
            } else if (
                userSelected?.status === 1 &&
                userSelected?.school_id !== schoolId
            ) {
                return (
                    <Alert severity="warning">
                        El usuario con el ID{' '}
                        <strong>{userSelected?.student_id}</strong> (
                        <strong>{userSelected?.name}</strong>{' '}
                        <strong>{userSelected?.last_name}</strong>{' '}
                        <strong>{userSelected?.second_last_name}</strong>) se encuentra
                        activo en la escuela: <strong>{schoolName.name}</strong>
                        , CCT: <strong>{schoolName.clave}</strong> en{' '}
                        <strong>{schoolName.location}</strong> Tel.{' '}
                        <strong>{schoolName.cellphone}</strong>. Debe estar
                        inactivo para poder darlo de alta en tu escuela.
                    </Alert>
                );
            } else if (
                userSelected?.status === 1 &&
                userSelected?.school_id === schoolId
            ) {
                return (
                    <Alert severity="error">
                        El usuario con el ID{' '}
                        <strong>{userSelected?.student_id}</strong> (
                        <strong>{userSelected?.name}</strong>{' '}
                        <strong>{userSelected?.last_name}</strong>{' '}
                        <strong>{userSelected?.second_last_name}</strong>) ya se
                        encuentra activo en esta misma escuela:{' '}
                        <strong>{schoolName.name}</strong>, CCT:{' '}
                        <strong>{schoolName.clave}</strong> en{' '}
                        <strong>{schoolName.location}</strong> Tel.{' '}
                        <strong>{schoolName.cellphone}</strong>. Es necesario
                        que el usuario esté inactivo para proceder con su
                        registro en una escuela distinta a la actual.
                    </Alert>
                );
            }
        }
        return 'none';
    };

    const displayStyle = getUserAlertContent() === 'none' ? 'none' : '';

    return (
        <Dialog
            open={openModal}
            fullWidth={true}
            maxWidth="md"
            disableEscapeKeyDown={loadingStatus === 'pending'}
            onClose={handleClose}
        >
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <div style={{ display: 'flex', color: fontColor.value }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1 }}
                    >
                        Agregar usuario <br />
                        <span style={{ fontSize: '15px' }}>
                            Existente en CURA
                        </span>
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <UsersSearchCura onChange={onSelectUser} />
                {error === false ? (
                    <Box>
                        {errorMessage && (
                            <Alert severity="error" onClose={handleCloseError}>
                                {errorMessage}
                            </Alert>
                        )}
                        {userSelected?.status === 0 &&
                            userSelected?.school_id !== schoolId ? (
                            <Box>
                                <Stack
                                    divider={
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                        />
                                    }
                                    direction={{ xs: 'column', sm: 'row' }}
                                    spacing={{ xs: 1, sm: 2, md: 4 }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Avatar
                                        alt="Foto del usuario"
                                        src={userSelected?.url_photo_profile}
                                        sx={{
                                            width: 180,
                                            height: 180,
                                            marginTop: 2,
                                            marginLeft: 2,
                                        }}
                                    />
                                    <List
                                        sx={{
                                            width: '100%',
                                            maxWidth: 360,
                                            bgcolor: 'background.paper',
                                        }}
                                    >
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PersonIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Usuario"
                                                secondary={`${userSelected?.name} ${userSelected?.last_name} ${userSelected?.second_last_name}`}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <SchoolIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Escuela"
                                                secondary={`ID: ${schoolName.school_id} - ${schoolName.name}`}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PeopleIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="información personal"
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            Email:{' '}
                                                            {
                                                                userSelected?.email
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            Tel:{' '}
                                                            {
                                                                userSelected?.cellphone
                                                            }
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PeopleIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Roles"
                                                secondary={getUserRole()}
                                            />
                                        </ListItem>
                                    </List>
                                </Stack>
                                <Box sx={{ alignItems: 'center' }}></Box>
                            </Box>
                        ) : (
                            <Grid
                                sx={{
                                    marginTop: 3,
                                    paddingRight: 5,
                                    display: displayStyle,
                                }}
                                container
                                alignItems="center"
                                justifyContent="center"
                            >
                                {getUserAlertContent()}
                            </Grid>
                        )}
                    </Box>
                ) : (
                    <Grid
                        sx={{
                            marginTop: 3,
                            paddingRight: 5,
                            display: error === true ? '' : 'none',
                        }}
                        container
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Alert severity="error">
                            No se encontro el usuario con el ID ingresado
                        </Alert>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                {userSelected?.status === 0 &&
                    userSelected?.school_id !== schoolId && (
                        <LoadingButton
                            size="small"
                            color="primary"
                            onClick={() => {
                                ActiveCuraUser();
                            }}
                            loading={loadingStatus === 'pending'}
                            disableEscapeKeyDown={loadingStatus === 'pending'}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                        >
                            Transferir
                        </LoadingButton>
                    )}
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={() => {
                        setOpenModal(false);
                    }}
                    loading={loadingStatus === 'pending'}
                    disableEscapeKeyDown={loadingStatus === 'pending'}
                    loadingPosition="start"
                    startIcon={loadingStatus === 'pending' ? <CircularProgress size={24} color="inherit" /> : null}
                    variant="contained"
                >
                    Cerrar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddCuraUserModal;
